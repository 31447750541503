import './bootstrap';
import '../css/app.css';
import '@vueform/multiselect/themes/default.css';
import 'vue3-perfect-scrollbar/style.css';
import base from './Mixins/base';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import { PerfectScrollbarPlugin } from 'vue3-perfect-scrollbar';
import { OhVueIcon, addIcons } from "oh-vue-icons";
import Vueform from '@vueform/vueform';
import vueformConfig from './../../vueform.config';

import * as Sentry from "@sentry/vue";

import { 
    BiInstagram, 
    RiFacebookFill, 
    CoLocationPin, 
    BiTelephone, 
    FaRegularEnvelope, 
    HiDesktopComputer,
    OiPerson,
    FaRegularBuilding,
    MdDevicehub,
    FaBell,
    FaChevronDown,
    FaChevronUp,
    FaRegularEdit,
    MdLogout,
    GiHamburgerMenu,
    IoClose,
    FaNotesMedical,
    RiAdminFill,
    BiPlusCircle,
    MdQuerystats,
    FaUserNurse,
    CoMedicalCross
} from "oh-vue-icons/icons";

addIcons(
    BiInstagram, 
    RiFacebookFill, 
    CoLocationPin, 
    BiTelephone, 
    FaRegularEnvelope, 
    HiDesktopComputer,
    OiPerson,
    FaRegularBuilding,
    MdDevicehub,
    FaBell,
    FaChevronDown,
    FaChevronUp,
    FaRegularEdit,
    MdLogout,
    GiHamburgerMenu,
    IoClose,
    FaNotesMedical,
    RiAdminFill,
    BiPlusCircle,
    MdQuerystats,
    FaUserNurse,
    CoMedicalCross
);

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {

        const app = createApp({ render: () => h(App, props) });

        if (import.meta.env.APP_ENV != 'local') {
            Sentry.init({
              app,
              dsn: "https://eb56177901c543e3b133cd44b6fdff18@o4508314049511424.ingest.de.sentry.io/4508353076985936",
              integrations: [
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
              ],
            // Session Replay
              replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
              replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            });
        }

        app.use(plugin)
            .use(PerfectScrollbarPlugin)
            .use(ZiggyVue, Ziggy)
            .use(Vueform, vueformConfig)
            .mixin(base)
            .component('v-icon', OhVueIcon)
            .mount(el);

        return app;
    },
    progress: {
        color: '#E83661',
    },
});
